import React from "react"
import PropTypes from "prop-types"
import NavItem from "../navigation/nav-item"

function AboutUsNavBar(props) {
  return (
    <>
      <NavItem
        {...props}
        val={navItems.missionAndCoreIdeology.name}
        link={navItems.missionAndCoreIdeology.link}
      />
      <NavItem
        {...props}
        val={navItems.origins.name}
        link={navItems.origins.link}
      />
      <NavItem
        {...props}
        val={navItems.placeBasedEducation.name}
        link={navItems.placeBasedEducation.link}
      />
      <NavItem
        {...props}
        val={navItems.boardOfDirectors.name}
        link={navItems.boardOfDirectors.link}
      />
      <NavItem
        {...props}
        val={navItems.graceAndJimmy.name}
        link={navItems.graceAndJimmy.link}
      />      
      <NavItem
        {...props}
        val={navItems.transparency.name}
        link={navItems.transparency.link}
      />
      <NavItem
        {...props}
        val={navItems.partners.name}
        link={navItems.partners.link}
      />
      <NavItem
        {...props}
        val={navItems.easternMichiganUni.name}
        link={navItems.easternMichiganUni.link}
        childOf={navItems.partners.name}
      />
      <NavItem
        {...props}
        val={navItems.aftMi.name}
        link={navItems.aftMi.link}
        childOf={navItems.partners.name}
      />
      <NavItem
        {...props}
        val={navItems.mentorSchools.name}
        link={navItems.mentorSchools.link}
        childOf={navItems.partners.name}
      />
      <NavItem
        {...props}
        val={navItems.communityPartners.name}
        link={navItems.communityPartners.link}
        childOf={navItems.partners.name}
      />
      <NavItem
        {...props}
        val={navItems.support.name}
        link={navItems.support.link}
        childOf={navItems.partners.name}
      />
      <NavItem
        {...props}
        val={navItems.contactUs.name}
        link={navItems.contactUs.link}
      />
    </>
  )
}

AboutUsNavBar.propTypes = {
  current: PropTypes.string.isRequired,
  setCurrent: PropTypes.func.isRequired,
}

export default AboutUsNavBar

export const navItems = {
  missionAndCoreIdeology: {
    name: "Mission & Core Ideology",
    link: "#mission-and-core-ideology",
  },
  placeBasedEducation: {
    name: "Place-Based Education",
    link: "#place-based-education",
  },
  boardOfDirectors: {
    name: "Board of Directors",
    link: "#board-of-directors",
  },
  graceAndJimmy: {
    name: "Grace & Jimmy",
    link: "#grace-and-jimmy",
  },
  origins: {
    name: "Origins",
    link: "#origins",
  },
  contactUs: {
    name: "Contact",
    link: "#contact-us",
  },
  transparency: { name: "Transparency", link: "#transparency" },
  partners: { name: "Partners", link: "#partners" },
  easternMichiganUni: {
    name: "Eastern Michigan University",
    link: "#partners-eastern-michigan-university",
  },
  aftMi: { name: "AFT - Michigan", link: "#partners-aft-michigan" },
  mentorSchools: {
    name: "Mentor Schools",
    link: "#partners-mentor-schools",
  },
  communityPartners: {
    name: "Community Partners",
    link: "#partners-community-partners",
  },
  support: { name: "Support", link: "#partners-support" },
}
