import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Footer from "./navigation/footer"
import ResponsiveNavigation from "./navigation/responsive-navigation"
import * as styles from "./layout.module.scss"

function Layout({
  children,
  activeHeader,
  setCurrentSwapperItem,
  setSwapperVisible,
}) {
  const [visible, setVisible] = useState(false)
  const [footerHeightOnScreen, setFooterHeightOnScreen] = useState(0)

  useEffect(() => {
    setVisible(true)
  }, [setVisible])

  if (visible) {
    return (
      <>
        <ResponsiveNavigation
          activeHeader={activeHeader}
          setCurrentSwapperItem={setCurrentSwapperItem}
          setSwapperVisible={setSwapperVisible}
          footerHeightOnScreen={footerHeightOnScreen}
        />
        <div>
          <main>{children}</main>
          <div className={styles.boggs_modal_container} id="boggs-school-modal"></div>
          <Footer setFooterHeightOnScreen={setFooterHeightOnScreen} />
        </div>
      </>
    )
  } else {
    return (
      <div className={styles.loader_container}>
        <div className={styles.loader}>
          <div className={styles.square} />
          <div className={styles.square} />
          <div className={styles.square} />
        </div>
        <h1 className={styles.text}>Loading</h1>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
