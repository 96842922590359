import React from "react"
import PropTypes from "prop-types"
import NavItem from "../navigation/nav-item"

function BlogNavBar(props) {
  return (
    <>
      <NavItem {...props} val={navItems.blog.name} link={navItems.blog.link} />
      <NavItem
        {...props}
        val={navItems.mediaGallery.name}
        link={navItems.mediaGallery.link}
      />
      <NavItem
        {...props}
        val={navItems.schoolSong.name}
        link={navItems.schoolSong.link}
      />
      <NavItem
        {...props}
        val={navItems.eigthGradeSpeeches.name}
        link={navItems.eigthGradeSpeeches.link}
      />
    </>
  )
}

BlogNavBar.propTypes = {
  current: PropTypes.string.isRequired,
  setCurrent: PropTypes.func.isRequired,
}

export default BlogNavBar

export const navItems = {
  blog: {
    name: "Blog",
    link: "#blog",
  },
  mediaGallery: {
    name: "Media Gallery",
    link: "#media-gallery",
  },
  schoolSong: {
    name: "School Song",
    link: "#school-song",
  },
  eigthGradeSpeeches: {
    name: "8th Grade Speeches",
    link: "#8th-grade-speeches",
  },
}
