import React, { useState, useEffect, useRef, useCallback } from "react"
import * as styles from "./header-nav-bar.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { graphql, Link, useStaticQuery } from "gatsby"
import { headerNames } from "./header"

function HeaderNavBar({ navBar, activeHeader }) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              hero_image_dark
            }
          }
        }
      }
    }
  `)

  const [visible, setVisible] = useState(styles.hidden)
  const [hamburgerLight, setHamburgerLight] = useState("")
  const [ariaHidden, setAriaHidden] = useState(true)

  const toggleVisible = useCallback(() => {
    if (visible.includes("visible")) {
      setVisible(styles.hidden)
      setAriaHidden(true)
    } else {
      setVisible(styles.visible)
      setAriaHidden(false)
    }
  }, [visible])

  const updateHamburger = () => {
    const nextHamburger =
      activeHeader === headerNames.home &&
      !query.allPrismicBoggsImageBucket.edges[0].node.data.hero_image_dark
        ? styles.hamburger__light
        : ""

    if (nextHamburger !== hamburgerLight) {
      setHamburgerLight(nextHamburger)
    }
  }

  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        visible.includes("visible")
      ) {
        toggleVisible()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef, visible, toggleVisible])

  const checkActive = (headerName) => {
    return activeHeader === headerName ? styles.active : ""
  }

  const checkActiveNav = (activeTabCheck) => {
    return activeTabCheck === styles.active
      ? styles.activeNav
      : styles.inactiveNav
  }

  const activeHome = checkActive(headerNames.home)
  const activeAboutUs = checkActive(headerNames.aboutUs)
  const activeTeam = checkActive(headerNames.team)
  const activeSchoolInformation = checkActive(headerNames.schoolInformation)
  const activeBlog = checkActive(headerNames.blog)
  const activeDonate = checkActive(headerNames.donate)

  const activeHomeNav = checkActiveNav(activeHome)
  const activeAboutUsNav = checkActiveNav(activeAboutUs)
  const activeTeamNav = checkActiveNav(activeTeam)
  const activeSchoolInformationNav = checkActiveNav(activeSchoolInformation)
  const activeBlogNav = checkActiveNav(activeBlog)
  const activeDonateNav = checkActiveNav(activeDonate)

  updateHamburger()
  return (
    <div ref={wrapperRef}>
      <button
        tabIndex={0}
        aria-label="Open sidebar navigation"
        className={`${styles.hamburger} ${hamburgerLight}`}
        onClick={() => toggleVisible()}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={`${styles.header_and_nav_container} ${visible}`}>
        <header className={styles.header_container}>
          <div className={styles.header}>
            <div>
              <div className={styles.header}>
                <Link to="/" className={styles.link}>
                  <h1 aria-hidden={ariaHidden}>Home</h1>
                  <div className={activeHome} />
                </Link>
                <div className={`${activeHomeNav} ${styles.nav_bar}`}>
                  {navBar}
                </div>
                <Link
                  className={styles.link}
                  to="/about-us#mission-and-core-ideology"
                >
                  <h1 aria-hidden={ariaHidden}>About Us</h1>
                  <div className={activeAboutUs} />
                </Link>
                <button
                  className={`${activeAboutUsNav} ${styles.nav_bar}`}
                  onClick={() => toggleVisible()}
                >
                  {navBar}
                </button>
                <Link className={styles.link} to="/team#leadership">
                  <h1 aria-hidden={ariaHidden}>Team</h1>
                  <div className={activeTeam} />
                </Link>
                <button
                  className={`${activeTeamNav} ${styles.nav_bar}`}
                  onClick={() => toggleVisible()}
                >
                  {navBar}
                </button>
                <Link className={styles.link} to="/school-information#faqs">
                  <h1 aria-hidden={ariaHidden}>School Information</h1>
                  <div className={activeSchoolInformation} />
                </Link>
                <button
                  className={`${activeSchoolInformationNav} ${styles.nav_bar}`}
                  onClick={() => toggleVisible()}
                >
                  {navBar}
                </button>
                <Link className={styles.link} to="/blog#blog">
                  <h1 aria-hidden={ariaHidden}>Blog</h1>
                  <div className={activeBlog} />
                </Link>
                <button
                  className={`${activeBlogNav} ${styles.nav_bar}`}
                  onClick={() => toggleVisible()}
                >
                  {navBar}
                </button>
                <Link
                  className={styles.link}
                  to="/donate"
                  onClick={() => toggleVisible()}
                >
                  <h1 aria-hidden={ariaHidden}>Donate</h1>
                  <div className={activeDonate} />
                </Link>
                <div className={`${activeDonateNav} ${styles.nav_bar}`}>
                  {navBar}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  )
}

export default HeaderNavBar
