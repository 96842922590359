import React from "react"
import NavItem from "../navigation/nav-item"

function SchoolInformationNavBar(props) {
  return (
    <>
      <NavItem {...props} val={navItems.faqs.name} link={navItems.faqs.link} />
      <NavItem
        {...props}
        val={navItems.applications.name}
        link={navItems.applications.link}
      />
      <NavItem
        {...props}
        val={navItems.artsAndAthletics.name}
        link={navItems.artsAndAthletics.link}
      />
      <NavItem
        {...props}
        val={navItems.schedules.name}
        link={navItems.schedules.link}
      />
      <NavItem
        {...props}
        val={navItems.schoolCalendars.name}
        link={navItems.schoolCalendars.link}
        childOf={navItems.schedules.name}
      />
      <NavItem
        {...props}
        val={navItems.familyTours.name}
        link={navItems.familyTours.link}
        childOf={navItems.schedules.name}
      />
      <NavItem
        {...props}
        val={navItems.foodService.name}
        link={navItems.foodService.link}
        childOf={navItems.schedules.name}
      />
      <NavItem
        {...props}
        val={navItems.schoolPolicies.name}
        link={navItems.schoolPolicies.link}
      />
      <NavItem
        {...props}
        val={navItems.familyAndStudentHandbook.name}
        link={navItems.familyAndStudentHandbook.link}
      />
    </>
  )
}

export default SchoolInformationNavBar

export const navItems = {
  faqs: {
    name: "FAQs",
    link: "#faqs",
  },
  applications: {
    name: "Applications",
    link: "#applications",
  },
  artsAndAthletics: { 
    name: "Arts & Athletics",
    link: "#arts-and-athletics",
  },
  schedules: {
    name: "Schedules",
    link: "#schedules",
  },
  schoolCalendars: {
    name: "School Calendars",
    link: "#school-calendars",
  },
  familyTours: {
    name: "Family Tours",
    link: "#family-tours",
  },
  foodService: {
    name: "Food Service",
    link: "#food-service",
  },
  schoolPolicies: {
    name: "School Policies",
    link: "#school-policies",
  },
  familyAndStudentHandbook: {
    name: "Family & Student Handbook",
    link: "#family-and-student-handbook",
  },
}
