import React, { useEffect, useRef, useCallback } from "react"
import * as styles from "./footer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelopeOpenText,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"

function Footer({ setFooterHeightOnScreen }) {
  const footerRef = useRef(null)

  const scrollListener = useCallback(
    (event) => {
      const gatsbySafeDocCheck =
        typeof window === `undefined`
          ? 0
          : document.body.getBoundingClientRect()
      const rect = gatsbySafeDocCheck
      const footerHeight = footerRef.current.clientHeight
      const footerTop = rect.height - footerHeight
      const pixelsOfFooterOnScreen =
        (footerTop - (window.innerHeight + rect.top * -1)) * -1

      setFooterHeightOnScreen(pixelsOfFooterOnScreen)
    },
    [setFooterHeightOnScreen]
  )

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [setFooterHeightOnScreen, scrollListener])

  return (
    <footer ref={footerRef} className={styles.container}>
      <div className={styles.footer_text}>
        <p>The James &amp; Grace Lee Boggs School</p>
        <p>7600 Goethe Street, Detroit, MI 48214</p>
        <div className={styles.phone_and_fax}>
          <p>(313) 923-2301 Phone</p>
          <p className={styles.fax}>(313) 923-2300 Fax</p>
        </div>
        <p>Office Hours: Monday - Friday 9am - 4pm </p>
        <p>
          Mural art created by{" "}
          <a
            className="light"
            href="https://www.katieyamasaki.com/"
            target="_blank"
          >
            <p className={styles.inline} tabindex={0}>Katie Yamasaki</p>
          </a>
        </p>
      </div>
      <div className={styles.links}>
        <a
          className={`${styles.link} light`}
          href="mailto:boggsschool@boggsschool.org"
        >
          <div tabindex={0}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
            <p>Contact</p>
          </div>
        </a>
        <a
          className={`${styles.link} light`}
          href="https://goo.gl/maps/i6c5msxp5yPzBhjd7"
          target="_blank"
        >
          <div tabindex={0}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <p>Visit</p>
          </div>
        </a>
        <a
          tabIndex={0}
          className={`${styles.link} light`}
          href="http://www.facebook.com/pages/The-Boggs-Educational-Center/244370322273"
          target="_blank"
        >
          <div tabindex={0}>
            <FontAwesomeIcon icon={faFacebook} />
            <p>Facebook</p>
          </div>
        </a>
        <a
          tabIndex={0}
          className={`${styles.link} light`}
          href="https://twitter.com/BoggsSchool"
          target="_blank"
        >
          <div tabindex={0}>
            <FontAwesomeIcon icon={faTwitter} />
            <p>Tweet</p>
          </div>
        </a>
      </div>
    </footer>
  )
}

export default Footer
