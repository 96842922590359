import React, { useState, useEffect, useRef } from "react"
import * as styles from "./nav-bar.module.scss"
import NavItem from "./nav-item"

function NavBar({ navBar, footerHeightOnScreen }) {
  const [scrollStyle, setScrollStyle] = useState("")
  const navBarRef = useRef(null)

  const updateScroll = () => {
    const gatsbySafeDocCheck =
      typeof window === `undefined` ? 0 : document.body.getBoundingClientRect()
    const rect = gatsbySafeDocCheck
    const scrollHeight = -100
    if (rect.y <= scrollHeight) {
      setScrollStyle(`${styles.scrolled}`)
    } else if (rect.y > scrollHeight) {
      setScrollStyle("")
    }
  }

  const overExtendNavBar = () => {
    if (navBarRef && navBarRef.current) {
      navBarRef.current.style.setProperty("height", "110vh")
    }
  }

  useEffect(() => {
    const updateNavHeightByFooter = () => {
      if (navBarRef && navBarRef.current) {
        if (footerHeightOnScreen > 0) {
          navBarRef.current.style.setProperty(
            "height",
            window.innerHeight - footerHeightOnScreen + 25 + "px"
          )
        } else {
          overExtendNavBar()
        }
      }
    }
  
    const scrollListener = event => {
      updateScroll()
      updateNavHeightByFooter()
    }
  
    const hashListener = () => {
      overExtendNavBar()
    }

    window.addEventListener("scroll", scrollListener)
    window.addEventListener("hashchange", hashListener)

    updateScroll()
    updateNavHeightByFooter()
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [footerHeightOnScreen])

  if (navBar.props.current === undefined) {
    return <></>
  } else {
    if (scrollStyle === "") {
      return (
        <div ref={navBarRef} className={`${styles.nav_bar} ${scrollStyle}`}>
          <ul>{navBar}</ul>
        </div>
      )
    } else {
      return (
        <div ref={navBarRef} className={`${styles.nav_bar} ${scrollStyle}`}>
          <ul>
            <NavItem val="Home" link="/" />
            {navBar}
          </ul>
        </div>
      )
    }
  }
}

export default NavBar
