import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import * as navBarStyles from "./nav-bar.module.scss"
import * as headerNavBarStyles from "./header-nav-bar.module.scss"

function NavItem(props) {
  const [childAnim, setChildAnim] = useState(navBarStyles.drop_down_hide)
  const [hoverIndicator, setHoverIndicator] = useState("")
  const aHrefLink = useRef(null)

  const navTo = () => {
    if (props.val === "Home") return

    window.scrollTo(0, 0)
    const fakeScroll = new Event("scroll")
    window.dispatchEvent(fakeScroll)

    aHrefLink.current.click()
    if (props.childOf) {
      props.setCurrentChild(props.val)
    } else {
      props.setCurrent(props.val)
      if (props.setCurrentChild) {
        props.setCurrentChild("")
      }
    }
  }

  //TODO: increase legibility, split child out as a separate item
  let isSelectedIndicator
  let isSelectedContainer
  if (props.childOf) {
    isSelectedIndicator =
      props.currentChild === props.val ? navBarStyles.indicator_active : ""
    isSelectedContainer =
      props.currentChild === props.val
        ? navBarStyles.nav_item_selected_backdrop
        : ""
  } else {
    isSelectedIndicator =
      props.current === props.val ? navBarStyles.indicator_active : ""
    isSelectedContainer =
      props.current === props.val ? navBarStyles.nav_item_selected_backdrop : ""
  }

  let childStyles = ""

  const setChildStyles = (childOfProp) => {
    if (childOfProp) {
      const isChildOfCurrent = props.current === childOfProp
      childStyles = isChildOfCurrent
        ? navBarStyles.child_li_display +
          " " +
          headerNavBarStyles.child_li_display
        : navBarStyles.child_li + " " + headerNavBarStyles.child_li

      if (isChildOfCurrent) {
        setTimeout(() => {
          setChildAnim(navBarStyles.drop_down_show)
        }, 50)
      } else if (childAnim !== navBarStyles.drop_down_hide) {
        setChildAnim(navBarStyles.drop_down_hide)
      }
    } else if (childAnim !== "") {
      setChildAnim("")
    }
  }

  const hover = () => {
    setHoverIndicator(navBarStyles.indicator_active)
  }

  const hoverOut = () => {
    setHoverIndicator("")
  }

  setChildStyles(props.childOf)

  return (
    <li
      className={`${childStyles} ${childAnim} ${isSelectedContainer} ${headerNavBarStyles.child_links_container}`}
    >
      <div
        className={navBarStyles.item}
        onClick={() => navTo()}
        onMouseEnter={() => hover()}
        onMouseLeave={() => hoverOut()}
        onKeyDown={(event) => {
          if (
            event.key.toLowerCase() === "enter" ||
            event.code.toLowerCase() === "enter"
          ) {
            navTo()
          }
        }}
      >
        <div
          className={`${navBarStyles.indicator} ${isSelectedIndicator} ${hoverIndicator}`}
        />
        <a ref={aHrefLink} href={props.link}>
          <p tabIndex={0} className="inline">
            {props.val}
          </p>
        </a>
      </div>
    </li>
  )
}

NavItem.propTypes = {
  val: PropTypes.string.isRequired,
  childOf: PropTypes.string,
}

export default NavItem
