import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./header.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = ({ activeHeader }) => {
  const query = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "boggs_school_logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 250, layout: FIXED)
        }
      }
    }
  `)

  const checkActive = (headerName) => {
    return activeHeader === headerName ? styles.active : ""
  }

  const activeAboutUs = checkActive(headerNames.aboutUs)
  const activeTeam = checkActive(headerNames.team)
  const activeSchoolInformation = checkActive(headerNames.schoolInformation)
  const activeBlog = checkActive(headerNames.blog)
  const activeDonate = checkActive(headerNames.donate)

  return (
    <header className={styles.header_container}>
      <div className={styles.header}>
        <div className={styles.links}>
          <h1 className={styles.title}>
            <Link to="/" className={styles.logo}>
              <div display="none" tabIndex={0}>
                <GatsbyImage
                  className={styles.header_image}
                  alt="The James &amp; Grace Lee Boggs School"
                  image={query.logo.childImageSharp.gatsbyImageData}
                />
              </div>
            </Link>
          </h1>
          <div className={styles.nav_bar}>
            <Link
              className={styles.link}
              to="/about-us#mission-and-core-ideology"
            >
              <h1 tabIndex={0}>About Us</h1>
              <div className={activeAboutUs} />
            </Link>
            <Link className={styles.link} to="/team#leadership">
              <h1 tabIndex={0}>Team</h1>
              <div className={activeTeam} />
            </Link>
            <Link className={styles.link} to="/school-information#faqs">
              <h1 tabIndex={0}>School Information</h1>
              <div className={activeSchoolInformation} />
            </Link>
            <Link className={styles.link} to="/blog#blog">
              <h1 tabIndex={0}>Blog</h1>
              <div className={activeBlog} />
            </Link>
            <Link className={styles.link} to="/donate">
              <h1 tabIndex={0}>Donate</h1>
              <div className={activeDonate} />
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

export const headerNames = {
  home: "Home",
  aboutUs: "About Us",
  team: "Team",
  schoolInformation: "School Information",
  blog: "Blog",
  donate: "Donate",
}
