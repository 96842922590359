import * as styles from "./navigation/nav-bar.module.scss"
import { navItems as aboutUs } from "./about-us/about-us-nav-bar"
import { navItems as team } from "./team/team-nav-bar"
import { navItems as schoolInformation } from "./school-information/school-information-nav-bar"
import { navItems as blog } from "./blog/blog-nav-bar"

export const getLinkName = () => {
  const data = whatPage()
  if (data === undefined) return ""

  const linkIndex = window.location.toString().lastIndexOf(data.linkString)
  const link = window.location
    .toString()
    .substring(linkIndex + data.trailingLinkSize, window.location.length)
  return link.replace("/", "")
}

const whatPage = () => {
  const location = window.location.toString()
  if (location.includes("about-us")) {
    return { trailingLinkSize: 9, linkString: "/about-us" }
  } else if (location.includes("team")) {
    return { trailingLinkSize: 5, linkString: "/team" }
  } else if (location.includes("school-information")) {
    return { trailingLinkSize: 19, linkString: "/school-information" }
  } else if (location.includes("blog")) {
    return { trailingLinkSize: 5, linkString: "/blog" }
  } else {
    return undefined
  }
}

export const getInitialNavItem = () => {
  const linkName = getLinkName()
  switch (linkName) {
    case "/about-us":
      return aboutUs.missionAndCoreIdeology.name
    case "/team":
      return team.leadership.name
    case "/school-information":
      return schoolInformation.faqs.name
    case "/blog":
      return blog.blog.name
    default:
      return "error"
  }
}

export const getFlattenedNavItems = () => {
  return Object.assign(aboutUs, team, schoolInformation, blog)
}

export const setInitialScreen = (
  navItemName,
  setCurrentNavItem,
  setCurrentSwapperItem,
  setSwapperVisible
) => {
  setCurrentNavItem(navItemName)
  setCurrentSwapperItem(navItemName)
  setSwapperVisible(true)
}

export const setCurrentScreen = (
  nextCurrent,
  setSwapperVisible,
  setCurrentNavItem,
  setCurrentSwapperItem
) => {
  setSwapperVisible(false)
  setCurrentNavItem(nextCurrent)
  setTimeout(() => {
    setCurrentSwapperItem(nextCurrent)
    setTimeout(() => setSwapperVisible(true), 250)
  }, 250)
}

export const getContentSwapAnimation = visible => {
  return visible
    ? styles.container_animation_show
    : styles.container_animation_hide
}

export const trimChildrenLinkUrls = url => {
  switch (url) {
    case aboutUs.easternMichiganUni.name:
    case aboutUs.aftMi.name:
    case aboutUs.mentorSchools.name:
    case aboutUs.communityPartners.name:
    case aboutUs.support.name:
      return aboutUs.partners.name
    case schoolInformation.schoolCalendars.name:
    case schoolInformation.familyTours.name:
    case schoolInformation.foodService.name:
      return schoolInformation.schedules.name
    case schoolInformation.schoolPolicies.name:
      return schoolInformation.schoolPolicies.name
    default:
      return url
  }
}
