import React, { useEffect, useState } from "react"
import NavBar from "./nav-bar"
import { useStaticQuery, graphql } from "gatsby"
import Header, { headerNames } from "./header"
import { DESKTOP_EX } from "../constants"
import HeaderNavBar from "./header-nav-bar"
import {
  setCurrentScreen,
  getLinkName,
  setInitialScreen,
  trimChildrenLinkUrls,
  getFlattenedNavItems,
} from "../content-swapper-utils"
import AboutUsNavBar from "../about-us/about-us-nav-bar"
import TeamNavBar from "../team/team-nav-bar"
import SchoolInformationNavBar from "../school-information/school-information-nav-bar"
import BlogNavBar from "../blog/blog-nav-bar"

function ResponsiveNavigation({
  activeHeader,
  setCurrentSwapperItem,
  setSwapperVisible,
  footerHeightOnScreen,
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [currentNavItem, setCurrentNavItem] = useState("")
  const [currentChildNavItem, setCurrentChildNavItem] = useState("")

  const loopForInitialScreen = (linkName) => {
    if (linkName.length > 0) {
      const navItems = getFlattenedNavItems()
      for (const property in navItems) {
        if (
          navItems[property].link === linkName ||
          navItems[property].link === `/${linkName}`
        ) {
          const result = trimChildrenLinkUrls(navItems[property].name)
          setInitialScreenWrapper(result)
          break
        }
      }
    }
  }

  const gatsbySafeDocCheck =
    typeof window === `undefined`
      ? 0
      : document.body.getBoundingClientRect().width

  const [width, setWidth] = useState(gatsbySafeDocCheck)

  const resizeListener = () => {
    const gatsbySafeDocCheck =
      typeof window === `undefined`
        ? 0
        : document.body.getBoundingClientRect().width
    setWidth(gatsbySafeDocCheck)
  }

  const runScreenLoad = () => {
    const linkName = getLinkName()
    if (linkName.length > 1) {
      loopForInitialScreen(linkName)
      setTimeout(
        () => {
          window.location.hash = linkName
        },
        100,
        9938
      )
    }
  }

  const onBackPressed = (event) => {
    runScreenLoad()
  }

  useEffect(() => {
    runScreenLoad()

    window.onpopstate = onBackPressed

    window.addEventListener("resize", resizeListener)
    return () => {
      clearTimeout(9938)
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  const setInitialScreenWrapper = (navItemName) => {
    setInitialScreen(
      navItemName,
      setCurrentNavItem,
      setCurrentSwapperItem,
      setSwapperVisible
    )
  }

  const setCurrentScreenWrapper = (nextCurrent) => {
    setCurrentScreen(
      nextCurrent,
      setSwapperVisible,
      setCurrentNavItem,
      setCurrentSwapperItem
    )
  }

  const setChildCurrentWrapper = (nextChildCurrent) => {
    setCurrentChildNavItem(nextChildCurrent)
  }

  const getNavBar = () => {
    switch (activeHeader) {
      case headerNames.aboutUs:
        return (
          <AboutUsNavBar
            current={currentNavItem}
            currentChild={currentChildNavItem}
            setCurrent={setCurrentScreenWrapper}
            setCurrentChild={setChildCurrentWrapper}
          />
        )
      case headerNames.team:
        return (
          <TeamNavBar
            current={currentNavItem}
            currentChild={currentChildNavItem}
            setCurrent={setCurrentScreenWrapper}
            setCurrentChild={setChildCurrentWrapper}
          />
        )
      case headerNames.schoolInformation:
        return (
          <SchoolInformationNavBar
            current={currentNavItem}
            currentChild={currentChildNavItem}
            setCurrent={setCurrentScreenWrapper}
            setCurrentChild={setChildCurrentWrapper}
          />
        )
      case headerNames.blog:
        return (
          <BlogNavBar
            current={currentNavItem}
            currentChild={currentChildNavItem}
            setCurrent={setCurrentScreenWrapper}
            setCurrentChild={setChildCurrentWrapper}
          />
        )
      default:
        return <></>
    }
  }

  if (width > DESKTOP_EX) {
    return (
      <>
        <Header
          siteTitle={data.site.siteMetadata.title}
          activeHeader={activeHeader}
        />
        <NavBar
          footerHeightOnScreen={footerHeightOnScreen}
          navBar={getNavBar()}
        />
      </>
    )
  } else {
    return <HeaderNavBar activeHeader={activeHeader} navBar={getNavBar()} />
  }
}

export default ResponsiveNavigation
