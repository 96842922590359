import React from "react"
import NavItem from "../navigation/nav-item"

function TeamNavBar(props) {
  return (
    <>
      <NavItem
        {...props}
        val={navItems.leadership.name}
        link={navItems.leadership.link}
      />
      <NavItem
        {...props}
        val={navItems.teachers.name}
        link={navItems.teachers.link}
      />
      <NavItem
        {...props}
        val={navItems.supportStaff.name}
        link={navItems.supportStaff.link}
      />
      <NavItem
        {...props}
        val={navItems.communityTeachers.name}
        link={navItems.communityTeachers.link}
      />
      <NavItem
        {...props}
        val={navItems.interns.name}
        link={navItems.interns.link}
      />
      <NavItem
        {...props}
        val={navItems.boardMembers.name}
        link={navItems.boardMembers.link}
      />
      <NavItem
        {...props}
        val={navItems.joinUs.name}
        link={navItems.joinUs.link}
      />
    </>
  )
}

export default TeamNavBar

export const navItems = {
  boardMembers: {
    name: "Board Members",
    link: "#board-members",
  },
  supportStaff: {
    name: "Support Staff",
    link: "#support-staff",
  },
  leadership: {
    name: "Leadership",
    link: "#leadership",
  },
  joinUs: {
    name: "Join Us!",
    link: "#join-us",
  },
  teachers: {
    name: "Teachers",
    link: "#teachers",
  },
  communityTeachers: {
    name: "Community Teachers",
    link: "#community-teachers",
  },
  interns: {
    name: "Interns",
    link: "#interns",
  },
}
